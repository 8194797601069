import HeaderPages from "Components/HeaderPages/HeaderPages";
import RecentCardProject from "Pages/Home/RecentProjects/RecentCardProject";
import img from "../../assets/images/main/Rectangle.png";
import iconDollar from "../../assets/images/icons/dollar-circle2.svg";
import iconCalendar from "../../assets/images/icons/calendar.svg";
import "./AllProjects.css";
import SideBarFilter from "Components/SideBarFilter/SideBarFilter";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";
import { set } from "rsuite/esm/utils/dateUtils";
import NoResults from "Components/NotFound/noResults";
function addCommas(number) {
  // Convert the number to a string
  let numStr = number?.toString();

  // Use a regular expression to insert commas every three digits
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numStr;
}
const AllProjects = () => {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [categoryData, setCategoryData] = useState();
  // const [showProviders, setShowProviders] = useState(false);
  // const [typefilter, setTypefilter] = useState([
  //   "providers",
  //   "advisors",
  //   "contractors",
  // ]);
  const [city, setCity] = useState();
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("/allprojects", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data.projects);
        setData(res.data.projects);
      });
  }, []);
  // useEffect(() => {
  //   if (typefilter.includes("providers")) {
  //     setShowProviders(true);
  //   } else {
  //     setShowProviders(false);
  //   }
  // }, [typefilter]);
  useEffect(() => {
    let newFilteredData = [];
    if (data) {
      if (data) {
        newFilteredData = [...data];
      }
      if (searchText != "") {
        newFilteredData = data.filter((item) => {
          return item.title.toLowerCase().includes(searchText.toLowerCase());
        });
      }

      if (city) {
        newFilteredData = newFilteredData.filter((item) => {
          return item.city.toLowerCase() == city.label.toLowerCase();
        });
        console.log(newFilteredData);
      }
      if (categoryData) {
        newFilteredData = newFilteredData.filter((item) => {
          console.log("tesing" + item);
          return item.category == categoryData.label;
        });
        console.log(newFilteredData);
      }

      setFilteredData(newFilteredData);
    }
  }, [searchText, city, data, categoryData]);
  return (
    <>
      <HelmetInfo titlePage={t("allProjectsTitle")} />

      <div className="all-projects-page">
        <HeaderPages
          titlePage={t("allProjectsTitle")}
          routePage={false}
          mainTitlePage={false}
          showMainTitlePage={false}
        />
        <div className="main-projects-page">
          <div className="row g-3">
            <div className="col-12  col-md-4">
              <SideBarFilter
                setSelectedCityes={setCity}
                searchContractorsByName={setSearchText}
                isActive={false}
                // showProviders={showProviders}
                showProviders={true}
                setSelectedCategory={setCategoryData}
                // setTypefilter={setTypefilter}
                // typefilter={typefilter}
              />
            </div>
            <div className="col-12   col-md-8">
              <div className="all-projects-content">
                <div className="row g-3">
                  {filteredData?.length > 0 ? (
                    filteredData?.map((item, index) => {
                      return (
                        <div className="col-12" key={index}>
                          <RecentCardProject
                            img={item.images || img}
                            projectId={item.id}
                            altImg={t("imgAlt")}
                            badgeText={item.category}
                            titleCard={item.title}
                            titleAddress={`${item.city} ,${item.region}`}
                            iconDollar={iconDollar}
                            priceCard={item.price}
                            typePrice={t("currency")}
                            textPrice={t("expectedValue")}
                            iconCalendar={iconCalendar}
                            dateCard={item.end_date}
                            textInfoDate={t("expectedDeliveryDate")}
                            numWatch={item.views}
                            textWatch={t("views")}
                            numOffer={item.offers_count}
                            textOffer={t("offers")}
                            numDay={item.since}
                            routeCard={`/productDetailsPage/${item.id}`}
                            Favorite={item.is_favorite}
                            jopType={item.type}
                            typeofsupply={item.category}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="recent-project-one card-one-tab">
                      <NoResults text={t("noProjects")} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProjects;

// import CardTab from "Components/CardTab/CardTab";
// import HeaderPages from "Components/HeaderPages/HeaderPages";
// import SideBarFilter from "Components/SideBarFilter/SideBarFilter";
// // import img from "../../assets/images/main/image1.png";
// import PaginationPage from "Components/Pagination/Pagination";
// import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
// import { useEffect, useState } from "react";
// import axiosInstance from "../../axios";
// import CardSkelaton from "Components/Skelaton/CardSkelaton";
// import { useTranslation } from "react-i18next";
// import { set } from "rsuite/esm/utils/dateUtils";
// import NoResults from "Components/NotFound/noResults";

// import RecentCardProject from "Pages/Home/RecentProjects/RecentCardProject";
// import img from "../../assets/images/main/Rectangle.png";
// import iconDollar from "../../assets/images/icons/dollar-circle2.svg";
// import iconCalendar from "../../assets/images/icons/calendar.svg";
// import "./AllProjects.css";

// const AllProjects = () => {
//   const allProjects = [
//     { id: 0 },
//     { id: 1 },
//     { id: 2 },
//     { id: 3 },
//     { id: 4 },
//     { id: 5 },
//   ];
//   const [loading, setLoading] = useState(true);
//   // const [providers, setProviders] = useState([]);
//   // const [advisor, setAdvisor] = useState([]);
//   // const [contractor, setContractor] = useState([]);
//   // const [badgeView, setBadgeView] = useState(6);

//   const [typefilter, setTypefilter] = useState([
//     "providers",
//     "advisors",
//     "contractors",
//   ]);
//   const [mergedAndShuffledArray, setMergedAndShuffledArray] = useState([]);
//   const [showenResult, setShowenResult] = useState([]);
//   const { t, i18n } = useTranslation();
//   const [timeFilter, setTimeFilter] = useState();
//   const [SelectedCityes, setSelectedCityes] = useState();
//   const [showProviders, setShowProviders] = useState(false);

//   const [SelectedCategory, setSelectedCategory] = useState();
//   useEffect(() => {
//     axiosInstance
//       .get("/allprojects", {
//         headers: {
//           "Accept-Language": i18n.language,
//         },
//       })
//       .then((res) => {
//         console.log(res.data.projects);
//         setLoading(false);
//         // setProviders(res.data.providers);
//         // setAdvisor(res.data.advisors);
//         // setContractor(res.data.contractors);
//       });
//   }, [i18n.language]);

//   useEffect(() => {
//     // Merge the arrays
//     let mergedArray = [...providers, ...advisor, ...contractor];

//     if (
//       typefilter.includes("providers") &&
//       typefilter.includes("advisors") &&
//       typefilter.includes("contractors")
//     ) {
//       mergedArray = [...providers, ...advisor, ...contractor];
//     } else if (
//       typefilter.includes("providers") &&
//       typefilter.includes("advisors")
//     ) {
//       mergedArray = [...providers, ...advisor];
//     } else if (
//       typefilter.includes("providers") &&
//       typefilter.includes("contractors")
//     ) {
//       mergedArray = [...providers, ...contractor];
//     } else if (
//       typefilter.includes("advisors") &&
//       typefilter.includes("contractors")
//     ) {
//       mergedArray = [...advisor, ...contractor];
//     } else if (typefilter.includes("providers")) {
//       mergedArray = [...providers];
//     } else if (typefilter.includes("advisors")) {
//       mergedArray = [...advisor];
//     } else if (typefilter.includes("contractors")) {
//       mergedArray = [...contractor];
//     }
//     console.log(mergedArray);
//     // Shuffle the merged array using Fisher-Yates shuffle algorithm
//     for (let i = mergedArray.length - 1; i > 0; i--) {
//       const j = Math.floor(Math.random() * (i + 1));
//       [mergedArray[i], mergedArray[j]] = [mergedArray[j], mergedArray[i]];
//     }
//     console.log(mergedArray);
//     if (SelectedCityes) {
//       const filterdData = mergedArray.filter(
//         (item) => item.city_id == SelectedCityes.value
//       );
//       mergedArray = filterdData;
//     }
//     if (SelectedCategory) {
//       const filterdData = mergedArray.filter(
//         (item) => item.category_id == SelectedCategory.value
//       );
//       mergedArray = filterdData;
//     }
//     if (timeFilter) {
//       // if(lastDay=="lastDay")
//       // {
//       //   const filterdData = mergedArray.filter(
//       //     (item) => item.last_day
//       //   );
//       //   mergedArray = filterdData;
//       // }
//     }

//     // Set the merged and shuffled array to state
//     setMergedAndShuffledArray(mergedArray);
//     setShowenResult(mergedArray);
//   }, [
//     typefilter,
//     advisor,
//     contractor,
//     providers,
//     SelectedCityes,
//     timeFilter,
//     SelectedCategory,
//   ]);

//   //++++++++++++++++++++++++++++ show provider filter ++++++++++++++++++++++++++++++++
//   useEffect(() => {
//     if (typefilter.includes("providers")) {
//       setShowProviders(true);
//     } else {
//       setShowProviders(false);
//     }
//   }, [typefilter]);

//   useEffect(() => {
//     console.log(SelectedCityes, showenResult);
//   }, [SelectedCityes]);
//   function searchContractorsByName(name) {
//     // Convert the search query to lowercase for case-insensitive search
//     const query = name.toLowerCase();

//     // Filter the contractors array based on the name
//     const searchResults = mergedAndShuffledArray?.filter(
//       (contractor) =>
//         contractor?.company_name_en?.toLowerCase().includes(query) ||
//         contractor?.company_name_ar?.toLowerCase().includes(query) ||
//         contractor?.name?.toLowerCase().includes(query)
//     );
//     setShowenResult(searchResults);
//   }

//   return (
//     <>
//       <HelmetInfo titlePage={t("allProjectsTitle")} />

//       <div className="all-projects-page">
//         <HeaderPages
//           titlePage={t("allProjectsTitle")}
//           routePage={false}
//           mainTitlePage={false}
//           showMainTitlePage={false}
//         />
//         <div className="main-projects-page">
//           <div className="row g-3">
//             <div className="col-12  col-md-4">
//               <SideBarFilter
//                 setTypefilter={setTypefilter}
//                 typefilter={typefilter}
//                 showProviders={showProviders}
//                 isActive={true}
//                 searchContractorsByName={searchContractorsByName}
//                 setSelectedCityes={setSelectedCityes}
//                 setTimeFilter={setTimeFilter}
//                 setSelectedCategory={setSelectedCategory}
//               />
//             </div>
//             <div className="col-12   col-md-8">
//               <div className="all-projects-content">
//                 <div className="row g-3">
//                   {showenResult?.length > 0 ? (
//                     showenResult?.map((item) => {
//                       return (
//                         <div className="col-12" key={item.id}>
//                           <RecentCardProject
//                             img={item.images || img}
//                             projectId={item.id}
//                             altImg={t("imgAlt")}
//                             badgeText={item.category}
//                             titleCard={item.title}
//                             titleAddress={`${item.city} ,${item.region}`}
//                             iconDollar={iconDollar}
//                             priceCard={item.price}
//                             typePrice={t("currency")}
//                             textPrice={t("expectedValue")}
//                             iconCalendar={iconCalendar}
//                             dateCard={item.end_date}
//                             textInfoDate={t("expectedDeliveryDate")}
//                             numWatch={item.views}
//                             textWatch={t("views")}
//                             numOffer={item.offers_count}
//                             textOffer={t("offers")}
//                             numDay={item.since}
//                             routeCard={`/productDetailsPage/${item.id}`}
//                             Favorite={item.is_favorite}
//                             jopType={item.type}
//                             typeofsupply={item.category}
//                           />
//                         </div>
//                       );
//                     })
//                   ) : (
//                     <div className="recent-project-one card-one-tab">
//                       <NoResults text={t("noProjects")} />
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AllProjects;
